import React from "react";
import {graphql,Link} from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import UtilityStoryitem from "../components/utilityStoryitem"
import Image from "gatsby-plugin-sanity-image"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}


export default({data,pageContext}) => {

  if(data.offers.totalCount == 0){
    //document.location.href = '/'
  }

  return(
    <Layout>
      <SEO title={firstLetterInWordsUpper(trimJunkChars(pageContext.tag)) + " | Gratis.no"}
           description={"Her finner du gratis "+ firstLetterInWordsUpper(pageContext.tag)}
           image={data.bgImage.childImageSharp.fixed}
      />

      {/*<section className="section novi-bg novi-bg-img section-lg">
        <div className="container">
          <div className="">
            <h1>{firstLetterInWordsUpper(trimJunkChars(pageContext.tag))}</h1>
          </div>
        </div>
      </section>*/}

      <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
        <div className="container">
          <h2 style={{marginBottom:'60px'}} className="text-center text-sm-left">{firstLetterInWordsUpper(trimJunkChars(pageContext.tag))}<span className="h2-style"></span></h2>
          <div className="row row-50">

            {data.offers.edges.map(({ node: offer }) => (
              <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
              </div>
            ))}

          </div>
          <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
            className="button button-icon button-icon-right button-black" href="/populare-gratisting/"><span
            className="icon novi-icon fa fa-chevron-right"/>Vis populære</Link></div>
        </div>
      </section>
    </Layout>
  )
}

function trimJunkChars(str){
  return str.replace(/[^a-zA-ZøæåÆØÅ ]/,"").trim()
}

function firstLetterInWordsUpper(str){
  let splitString = str.split(" ")
  let retString = ""

  for(let i =0; i < splitString.length; i++){
    retString += splitString[i].charAt(0).toLocaleUpperCase() + splitString[i].slice(1) + " "
  }

  return retString.trim()
}

export const query = graphql`
    query ($tag: String){ 
    
        bgImage:  file(relativePath: { eq: "home-01-1920x570.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 570) {
          src
        }
      }
    }
    
      offers: allSanityOffer(filter: {isDeleted: {ne: true},tags: {elemMatch: {tag: {eq: $tag}}}}){
        totalCount
        edges{
          node{
            slug{current}
            title
            gkid{current}
            value
            image {
                ...Image
                }
            _rawDescription
          }
        }
      }

    popOffer: allSanityOffer(limit: 4,filter:{isDeleted: {ne: true}}, sort: {fields: popularity_rank, order: DESC}) {
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image{
                ...Image
            }
          }
        }
      }
    }
  `


